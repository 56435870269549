<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">园区展示</div>
      <div class="h2">科技铸就辉煌品质,循环打造生态园区</div>
    </div>
    <div class="title1 flex"><span class="flex-1"></span>产业园区<span class="flex-1"></span></div>
    <div class="zhuti">
      <div class="news flex" v-for="(ar,index) in arr" :key="index" @click="goxq(ar)">
          <div>
            <el-image  class="image" fit="fill" :src="ar.image" lazy></el-image>
          </div>
        <div class="flex-1 desc">
          <div class="title">{{ar.name}}</div>
          <div>
            <span class="biaoqian">{{ar.level}}</span>
          </div>
          <div>园区面积：{{ar.area}}</div>
          <div>上云企业：{{ar.enterpriseQty}}家</div>
          <div>园区地址：{{ar.provinceName}}{{ar.cityName}}{{ar.countyName}}{{ar.address}}</div>
          <div>主导产业：{{ar.industry}}</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {parklist} from "../../request/moudle/park";
export default {
  name: "zchj",
  data(){
    return {
      page:{
        current:1,
        size:10,
        total:0
      },
      arr:[],
    }
  },
  created() {
    this.loadlist()
  },
  methods:{
    goxq(item){
      this.$router.push({
        path:'/yqglxq',
        query:{
          id:item.id,
        }
      })
    },
    loadlist(bol){
      this.$api.park.parklist().then((res)=>{
        this.arr=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.zchjs{
  background: #F5F5F5;
}
.jiedu{
  font-weight: bolder;
  margin-top: 10px;
  span{
    margin: 0 10px;
  }
}
.artime{
  display: flex;
  align-items: flex-end;
}
.news{
  background: #fff;
  color: #3B3E45;
  overflow: hidden;
  width: 540px;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  float: left;
  margin-left: 20px;
  cursor: pointer;
  .image{
    width: 280px;
    height: 200px;
    margin-right: 15px;
  }
  .desc{
    color: #4C4B4B;
    div{
      padding: 5px 0;
    }
  }
  .title{
    font-weight: bolder;
  }
  .biaoqian{
    background:linear-gradient(0deg,#0078F2,#00BAFF);
    padding: 0px 8px;
    margin-right: 10px;
    border-radius: 4px;
    color: #fff;
  }
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 62px;
  .left{
    width: 535px;
    margin-right: 25px;
    color: #fff;
    .cont{
      height: 249px;
    }
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}
.zhuti{
  width: 1200px;
  margin:30px auto;
  .sxx{
    background: #fff;
    padding: 20px;
  }
}
.title1{
  width: 1200px;
  margin:30px auto;
  position: relative;
  font-size: 20px;
  font-weight:550;
  color:#000000 ;
  span{
    border-top: 1px solid #000000;
    margin: 0 15px;
    margin-top: 14px;
  }
}
</style>